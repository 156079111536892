<template>
  <div>
    <el-form
      :model="optionalFormData"
      ref="optionalFormData"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="模版名称" prop="templateName">
        <el-input
          type="text"
          show-word-limit
          v-model="optionalFormData.templateName"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="组名称" prop="name">
        <el-input
          type="text"
          show-word-limit
          v-model="optionalFormData.name"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="roleCodeList"
        :label="chooseLabel2"
        prop="parentId"
        v-show="info.type != 1"
      >
        <el-select
          v-model="optionalFormData.parentId"
          class="childWidth"
          filterable
          placeholder="请选择"
          @visible-change="evaluationTemplateSelect2($event)"
          @change="chooseModule2($event)"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in evaluationTemplateOptions2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="roleCodeList"
        :label="chooseLabel"
        prop="targetIdList"
      >
        <el-select
          v-model="optionalFormData.targetIdList"
          class="childWidth"
          filterable
          multiple
          placeholder="请选择"
          @visible-change="evaluationTemplateSelect($event)"
          @change="chooseModule($event)"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in evaluationTemplateOptions"
            :key="item.id"
            :label="info.type != 3 ? item.name : item.question"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="选填操作" prop="selected">
        <el-radio v-model="optionalFormData.selectedAction" label="1">
          {{ optionalFormData.targetIdList.length }}个中选
          <el-input-number
            type="text"
            show-word-limit
            v-model="optionalFormData.selected"
            :min="0"
            :max="optionalFormData.targetIdList.length"
          ></el-input-number>
          个以上
        </el-radio>
      </el-form-item>
      <el-form-item label="启用状态" class="roleCodeList2" prop="enabled">
          <el-switch
            v-model="optionalFormData.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用"
            active-value=1
            inactive-value=0
          >
          </el-switch>
        </el-form-item>
      <el-form-item label="" prop="" class="roleCodeList">
        <el-button type="primary" size="mini" @click="openDialog('add')"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <div class="evaluationGroup" v-show="evaluationGroup != null">
      <el-table border style="width: 100%" :data="evaluationGroup">
        <el-table-column label="组" prop="name" width="80"></el-table-column>
        <el-table-column :label="groupItemListLabel" prop="groupItemList">
          <template slot-scope="scope">
            <span
              class="span-tag-list"
              v-for="(item, index) in scope.row.groupItemList"
              :key="index"
            >
              <span>
                <span
                  class="targetNameIndex"
                  v-show="scope.row.groupItemList.length > 1"
                  >{{ index + 1 }}.</span
                >
                {{ item.targetName }}
              </span>
              <br />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="选填操作" prop="action">
          <template slot-scope="scope">
            <span
              v-show="
                scope.row.selected > 0 && scope.row.selected != scope.row.total
              "
            >
              <span>{{
                scope.row.action == 1
                  ? "至少"
                  : scope.row.action == 1
                  ? "至多"
                  : ""
              }}</span
              >{{ scope.row.total }}选{{ scope.row.selected }}
            </span>
            <span v-show="scope.row.selected == 0"> 可选 </span>
            <span v-show="scope.row.selected == scope.row.total"> 必选 </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="enabled">
          <template slot-scope="scope">
            <span>
              <span>{{
                scope.row.enabled == "0"
                  ? "禁用"
                  : scope.row.enabled == "1"
                  ? "启用"
                  : ""
              }}</span>
            </span>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="deleteOptionalOne(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      class="edit"
      :title="editTitle"
      :visible="editDialog"
      center
      @close="editDialogClose"
      width="1000px"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        :model="editFormData"
        ref="editFormData"
        :rules="editRules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="right"
      >
        <!-- <el-form-item label="模版名称" prop="templateName">
          <el-input
            type="text"
            show-word-limit
            v-model="editFormData.templateName"
            disabled
          ></el-input>
        </el-form-item> -->
        <el-form-item class="roleCodeList" label="组名称" prop="name">
          <el-input
            type="text"
            show-word-limit
            v-model="editFormData.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="roleCodeList"
          :label="chooseLabel2"
          prop="parentId"
          v-show="info.type != 1"
        >
          <el-select
            v-model="editFormData.parentId"
            class="childWidth"
            filterable
            placeholder="请选择"
            @visible-change="evaluationTemplateSelect2($event)"
            @change="chooseModule2($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in evaluationTemplateOptions2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="roleCodeList"
          :label="chooseLabel"
          prop="targetIdList"
        >
          <el-select
            v-model="editFormData.targetIdList"
            class="childWidth"
            filterable
            multiple
            placeholder="请选择"
            @visible-change="evaluationTemplateSelect($event)"
            @change="chooseModule($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in evaluationTemplateOptions"
              :key="item.id"
              :label="info.type != 3 ? item.name : item.question"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选填操作" class="roleCodeList2" prop="selected">
          <el-radio v-model="editFormData.selectedAction" label="1">
            {{ editFormData.targetIdList.length }}个中选
            <el-input-number
              type="text"
              show-word-limit
              v-model="editFormData.selected"
              :min="0"
              :max="editFormData.targetIdList.length"
            ></el-input-number>
            个以上
          </el-radio>
        </el-form-item>
        <el-form-item label="启用状态" class="roleCodeList2" prop="enabled">
          <el-switch
            v-model="editFormData.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用"
            active-value=1
            inactive-value=0
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="" prop="" class="roleCodeList">
          <el-button
            type="primary"
            size="mini"
            @click="editEvaluationTemplateGroup"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  addEvaluationTemplateGroup,
  editEvaluationTemplateGroup,
  deleteEvaluationTemplateGroup,
  evaluationTemplateItemGroupDetail,
  getEvaluationTemplateGroupList,
  evaluationTemplateModuleSelectList,
  evaluationTemplateSubModuleSelectList,
  evaluationTemplateItemSelectList,
} from "@/api/evaluationTemplate";

export default {
  name: "editOptional",
  data() {
    return {
      title: "新增",
      optionalFormData: {
        templateName: null,
        name: null,
        type: this.info.type,
        action: 1,
        selected: null,
        templateId: null,
        targetIdList: [],
        selectedAction: "1",
        enabled: "1",
      },
      rules: {
        name: [{ required: false, message: "请输入组名称", trigger: "blur" }],
        targetIdList: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        parentId: [{ required: true, message: "请选择", trigger: "change" }],
        selected: [
          { required: true, message: "请输入选填数量", trigger: "blur" },
        ],
      },
      editRules: {
        name: [{ required: false, message: "请输入组名称", trigger: "blur" }],
        targetIdList: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        selected: [
          { required: true, message: "请输入选填数量", trigger: "blur" },
        ],
        parentId: [{ required: true, message: "请选择", trigger: "change" }],
      },
      //下拉框
      evaluationTemplateOptions: [],
      evaluationTemplateOptions2: [],
      evaluationTemplateSubModuleOptions: [],
      evaluationTemplatItemOptions: [],
      //
      evaluationGroup: null,

      editDialog: false,
      editTitle: "编辑",
      editFormData: {
        templateName: null,
        name: null,
        type: this.info.type,
        action: 1,
        selected: null,
        templateId: null,
        targetIdList: [],
        selectedAction: null,
        enabled: null,
      },
      chooseLabel: "选择模块",
      chooseLabel2: null,
      moduleId: null,
      subModuleId: null,
      groupItemListLabel: null,
    };
  },
  props: {
    type: String,
    evaluationGroupDialog: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal) {
          this.getEvaluationTemplateGroupList(this.info);
          this.optionalFormData.templateName = this.info.templateName;
          this.optionalFormData.templateId = this.info.templateId;
          this.optionalFormData.type = this.info.type;
          console.log(this.info.type + "this.info.type");
          if (this.info.type == 1) {
            this.chooseLabel = "选择模块";
            this.groupItemListLabel = "模块列表";
          } else if (this.info.type == 2) {
            this.chooseLabel = "选择细分模块";
            this.chooseLabel2 = "选择模块";
            this.groupItemListLabel = "细分模块列表";
          } else if (this.info.type == 3) {
            this.chooseLabel = "选择评估元素";
            this.chooseLabel2 = "选择细分模块";
            this.groupItemListLabel = "评估元素列表";
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    // VueTreeList,
  },
  created() {
    // this.getQualityList();
    if (this.type == "edit") {
      this.title = "编辑评估模版" + " —— " + this.info.name;
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  mounted() {},
  methods: {
    evaluationTemplateItemGroupDetail(data) {
      evaluationTemplateItemGroupDetail(data).then((response) => {
        if (response.success) {
          this.editFormData = response.data;
          this.editFormData.enabled = String(this.editFormData.enabled);
          this.editTitle = "编辑——" + this.editFormData.name;
          this.evaluationTemplateSelect(true);
          this.evaluationTemplateSelect2(true);
          this.editDialog = true;
        }
      });
    },
    editDialogClose() { 
      this.editDialog = false;
    },
    addEvaluationTemplateGroup(data) {
      addEvaluationTemplateGroup(data).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.$refs["optionalFormData"].resetFields();
          this.getEvaluationTemplateGroupList(this.info);
        }
      });
    },
    editEvaluationTemplateGroup() {
      editEvaluationTemplateGroup(this.editFormData).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "编辑成功!",
          });
          this.$refs["editFormData"].resetFields();
          this.editDialog = false;
          this.getEvaluationTemplateGroupList(this.info);
          this.$forceUpdate()
        }
      });
    },
    openDialog(type, row) {
      if (type == "add") {
        console.log(this.optionalFormData);
        // let { name, type, action, selected, templateId, targetIdList } =
        //   this.optionalFormData;
        this.addEvaluationTemplateGroup(this.optionalFormData);
      } else if (type == "edit") {
        let info = {
          id: row.id,
        };
        this.evaluationTemplateItemGroupDetail(info);
      }
    },
    deleteOptionalOne(id) {
      this.$confirm("确定要删除此分组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          let info = {
            id: id,
          };
          deleteEvaluationTemplateGroup(info).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              let info = {
                templateId: this.info.templateId,
                type: this.info.type,
              };
              this.getEvaluationTemplateGroupList(info);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //下拉框2 start
    evaluationTemplateSelect2(cb) {
      if (this.info.type == 2) {
        this.evaluationTemplateModuleSelectList2(cb);
      } else if (this.info.type == 3) {
        this.evaluationTemplateSubModuleSelectList2(cb);
      }
    },
    evaluationTemplateModuleSelectList2(cb) {
      if (cb) {
        evaluationTemplateModuleSelectList({
          templateId: this.info.templateId,
        }).then((response) => {
          if (response.success) {
            let evaluationTemplateOptions = response.data;
            evaluationTemplateOptions.forEach((item, index) => {
              item.id = String(item.id);
            });
            this.evaluationTemplateOptions2 = evaluationTemplateOptions;
          }
        });
      }
    },

    chooseModule2(id) {
      var that = this;
      console.log(id);

      if (!this.editDialog) {
        this.optionalFormData.targetIdList = [];
      } else {
        this.editFormData.targetIdList = [];
      }
      if (this.info.type == 2) {
        this.moduleId = id;
      } else if (this.info.type == 3) {
        this.subModuleId = id;
      }
      this.evaluationTemplateOptions2.forEach((item, index) => {
        if (item.id == id) {
          this.optionalFormData.parentName = item.name;
        }
      });
    },
    evaluationTemplateSubModuleSelectList2(cb) {
      if (cb) {
        var info;
        if (this.info.type == 2) {
          info = {
            templateId: this.info.templateId,
            moduleId: this.moduleId,
          };
        } else if (this.info.type == 3) {
          info = {
            templateId: this.info.templateId,
          };
        }

        evaluationTemplateSubModuleSelectList(info).then((response) => {
          if (response.success) {
            let evaluationTemplateOptions = response.data;
            evaluationTemplateOptions.forEach((item, index) => {
              item.id = String(item.id);
            });
            this.evaluationTemplateOptions2 = evaluationTemplateOptions;
          }
        });
      }
    },

    // end
    //下拉框 start
    evaluationTemplateSelect(cb) {
      if (this.info.type == 1) {
        this.evaluationTemplateModuleSelectList(cb);
      } else if (this.info.type == 2) {
        this.evaluationTemplateSubModuleSelectList(cb);
      } else if (this.info.type == 3) {
        this.evaluationTemplateItemSelectList(cb);
      }
    },
    evaluationTemplateModuleSelectList(cb) {
      if (cb) {
        evaluationTemplateModuleSelectList({
          templateId: this.info.templateId,
        }).then((response) => {
          if (response.success) {
            let evaluationTemplateOptions = response.data;
            evaluationTemplateOptions.forEach((item, index) => {
              item.id = String(item.id);
            });
            this.evaluationTemplateOptions = evaluationTemplateOptions;
          }
        });
      }
    },

    chooseModule(id) {
      var that = this;
      console.log(id);
      this.optionalFormData.selected = 0;
    },
    evaluationTemplateSubModuleSelectList(cb) {
      if (cb) {
        var info;
        if (this.info.type == 2) {
          info = {
            templateId: this.info.templateId,
            moduleId: this.moduleId,
          };
        } else if (this.info.type == 3) {
          info = {
            templateId: this.info.templateId,
          };
        }
        evaluationTemplateSubModuleSelectList(info).then((response) => {
          if (response.success) {
            let evaluationTemplateOptions = response.data;
            evaluationTemplateOptions.forEach((item, index) => {
              item.id = String(item.id);
            });
            this.evaluationTemplateOptions = evaluationTemplateOptions;
          }
        });
      }
    },
    evaluationTemplateItemSelectList(cb) {
      if (cb) {
        var info = {
          templateId: this.info.templateId,
          subModuleId: this.subModuleId,
        };
        evaluationTemplateItemSelectList(info).then((response) => {
          if (response.success) {
            let evaluationTemplateOptions = response.data;
            evaluationTemplateOptions.forEach((item, index) => {
              item.id = String(item.id);
            });
            this.evaluationTemplateOptions = evaluationTemplateOptions;
          }
        });
      }
    },
    //end
    //选填——分组详情 start
    getEvaluationTemplateGroupList(data) {
      this.evaluationGroup = [];
      getEvaluationTemplateGroupList(data).then((response) => {
        if (response.success) {
          this.evaluationGroup = response.data;
          console.log(response.data[0].enabled)
          this.$forceUpdate()
        }
      });
    },
    //end

    // 点击tab
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
        this.optionalFormData.name = this.info.templateName;
        let info = {
          templateId: this.info.templateId,
          type: this.info.type,
        };
        this.getEvaluationTemplateGroupList(info);
      }
    },
    handleClick2(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
        // this.optionalFormData.name = this.formData.name;
      }
      let info = {
        templateId: this.info.templateId,
        type: Number(index) + 1,
      };
      this.getEvaluationTemplateGroupList(info);
    },

    //弹窗关闭
    dialogClose() {
      //   this.formData = {};
      this.$emit("listenEnevaluationGroupClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.red {
  color: red;
}
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .roleCodeList2 {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
.addDailog2 {
}
.el-container {
  min-height: 25rem;
  overflow-y: auto;
  .el-header {
    height: auto !important;
    .el-input {
      height: 1rem !important;
      input {
        height: 1rem !important;
      }
    }
  }
}
.treeInput {
}
.el-input__inner {
  height: 1rem !important;
}

// start

.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;
    &:hover {
      background-color: #d0cfcf;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.icon {
  // font-size: 1rem;
  margin: 0 0.3rem;
  &:hover {
    cursor: pointer;
  }
  .icon {
    width: 1rem;
  }
}

.muted {
  color: gray;
  font-size: 80%;
}

// end

.modularCenter {
  text-align: center;
  margin-bottom: 10px;
}
.modularButton {
  // margin: 0.2rem 0;
}
.modularBottom {
  // width: 100%;
  .left {
    // width: 40%;
    align-self: flex-start !important;
  }
  .right {
    // width: 60%;
    align-self: flex-start !important;
    .evaluationElementsList {
      margin: 5px 0;
      .one {
        width: 39%;
        // margin: 0 5px;
      }
      .one_ {
        margin: 0 5px;
      }
      .evaluationElementsScore {
        width: 12% !important;
        // float: right;
        text-align: center;
        i {
          margin: 0 3px;
        }
      }
    }
    .center {
      text-align: center;
    }
  }
}
.el-tree-node__content {
  width: 100%;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  width: 60%;

  // padding-right: 20px;
  /* 修改el-input高度，方案一： */
  /* 某些预处理器(sass)无法识别>>>，建议使用方案二 */
  /* >>> .el-input__inner {
  height: 20px;
} */
  /* 修改el-input高度，方案二： */
  /deep/ .el-input__inner {
    height: 20px;
    // width: 80%;
  }

  .breakWord2 {
    width: 80%;
    text-align: left;
  }
}

i {
  font-size: 18px;
  margin: 0 3px;
}
.itemInfo {
  margin-bottom: 1rem;
  span:first-child {
    font-weight: bold;
    font-size: 16px;
  }
}
.span-tag-list {
  .item:last-child .d {
    display: none;
  }
}
.evaluationGroup {
  //   height: 10rem;
  .targetNameIndex {
    font-weight: bold;
  }
}
</style>
